import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TimelineLite, Elastic } from "gsap/all"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../scss/pages/categoriesIndex.scss"

class CategoriesIndex extends React.Component {
	constructor(props) {
    super(props)
    this.postTween = new TimelineLite({delay: 0.2})
    this.postItems = []
  }
  componentDidMount() {
    this.postTween.staggerTo(this.postItems, 2 , { ease: Elastic.easeOut.config(1, 0.4) , y:0, opacity: 1} , 0.1)
  }
	render() {
		const { data } = this.props
    const posts = data.allWordpressCategory.edges

		return (
			<Layout location={this.props.location} crumbLabel="Categories">
				<SEO title="カテゴリー" keywords="カテゴリー一覧" />

				<div className="categoriesIndexWrap">
					<div className="categoriesIndex">
						<h2 className="pageSectionTitle">
							<span className="pageSectionTitle_l">Categories</span>
							<span className="pageSectionTitle_s">カテゴリー一覧</span>
						</h2>
						<ul className="categoriesIndexList">
							{posts.map((post, i) => (
								<li key={post.node.id} ref={li => this.postItems[i] = li}>
									<AniLink fade duration={1} className="categoriesIndexList_item" to={`/categories/${post.node.slug}`} >
										<span className="categoriesIndexList_item-l">{post.node.name}</span>
										<span className="categoriesIndexList_item-s">{post.node.slug}</span>
									</AniLink>
								</li>
							))}
						</ul>

					</div>
				</div>
			</Layout>
		)
	}
}

export default CategoriesIndex

export const query = graphql`
  query {
    allWordpressCategory {
			edges {
				node {
					id
					slug
					name
				}
			}
		}
  }
`